import Layout from "components/Layout";
import Page from "components/Page";
import SEO from "components/Seo";
import React from "react";

function ThanksPage() {
  return (
    <Layout>
      <SEO title="Thanks" />
      <Page
        data={{
          heroSectionHeading: "Thanks for your message",
          heroSectionImage: {
            fluid: {
              src:
                "//images.ctfassets.net/gnax899ipmuy/4HNXXIwV8kUgn4fPUroz1O/478618ed48bfdad9b681d8c4b6a02a89/about-1.jpg?w=800&q=50",
            },
          },
          bodySectionContent: {
            childMarkdownRemark: {
              html: "<p>We will get back to you as soon as possible.</p>",
            },
          },
        }}
      />
    </Layout>
  );
}

export default ThanksPage;
